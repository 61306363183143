.neonSubTitleWrapper {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.neonSubTitle {
  margin-top: 20px;
  font-size: 35px;
  color: #e7e7e7;
  text-shadow:
      0 0 7px #cacaca,
      0 0 10px #bebebe,
      0 0 21px #c0c0c0,
      0 0 42px rgb(112, 255, 207),
      0 0 82px rgb(0, 231, 154),
      0 0 92px rgb(0, 197, 131),
      0 0 102px #0fa,
      0 0 151px rgb(0, 161, 108);
}

.employeeCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
