.Research {
    text-align: center;
    margin-top: 20px;
}

.card {
    border: 2px solid #ffffff;
    border-radius: 15px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px; /* Adjust width as needed */
    margin: 0 auto; /* Center align the card */
}

.neonSubTitle {
    margin-bottom: 10px;
    font-size: 24px;
    color: #ffffff;
}

.card {
    margin-bottom: 10px;
    font-size: 24px;
    color: #333;
}

.card p {
    margin-bottom: 15px;
    font-size: 18px;
    color: #666;
}

.card img {
    border-radius: 15px; /* Rounded corners */
    max-width: 100%; /* Ensure image doesn't overflow */
    height: auto; /* Maintain aspect ratio */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: shadow for image */
}
