.employeeCard {
    border: 2px solid #ffffff;
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    margin: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.employeeImg img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-bottom: 10px;
}

.employeeDetails h3 {
    margin: 10px 0 5px;
    font-size: 24px;
    color: #333;
}

.employeeDetails p {
    margin: 0;
    font-size: 18px;
    color: #666;
}
